import * as React from "react"
import { Aiuto } from '@fidize/website.pages.cart.aiuto';
import { Hpadding } from '@fidize/ui.hpadding';
import { Vspace } from '@fidize/ui.vspace';
import { Text } from '@fidize/ui.text';
import { Section } from '@fidize/ui.section';
import { Flex } from '@fidize/ui.flex';
import { Responsive } from '@fidize/ui.responsive';
import { Link } from 'gatsby'

const Faq = () => {
    var desk =  <Section padding="1" bkg="grey">
                    <Flex type="column">
                        <Hpadding padding="1">
                            <Flex type="justify_center">
                                <Text type="h1 black">FAQ</Text>
                            </Flex>
                            <Vspace desk="40px" mobile="30px" />
                            <Aiuto />
                        </Hpadding>
                    </Flex>
                </Section>

    var mobile =  <Section padding="1" bkg="grey">
                    <Flex type="column">
                        <Hpadding padding="mobile0">
                            <Flex type="justify_center">
                                <Text type="h1 black">FAQ</Text>
                            </Flex>
                            <Vspace desk="40px" mobile="30px" />
                            <Aiuto />
                        </Hpadding>
                    </Flex>
                </Section>
    return (
        <>
            <head>
                <title>Fidize - FAQ</title>
            </head>
            <Responsive desk={desk} mobile={mobile} />
        </>
    )
}

export default Faq
